.applyCard {
  border-radius: 10px;
}

.link-cont {
  position: relative;
  bottom: -7px;
}
.link-cont #err-msg {
  position: absolute;
  bottom: -5px;
  left: -5px;
  font-size: 10px !important ;
}

.custom-segmented-control {
  font-size: 16px; /* Set the font size to make segments larger */
  padding: 10px; /* Add padding for better appearance */
  width: 100%; /* Make the segmented control span the full width */
  display: flex; /* Use flexbox to distribute tabs evenly */
  justify-content: space-between; /* Evenly distribute tabs */
  justify-items: center;
  align-items: center;
  overflow: hidden;
  align-content: space-around;text-overflow: ellipsis;
}
.ant-segmented-item {
  font-size: 20px;
  width: 30%;
  margin: 5px;
  /* font-weight: bold; */
  display: flex;
  
  justify-content: space-around;
}

