.selected_chat{
background-color: grey!important; 
}
.chat-box{
     border-bottom: 0.1px solid rgb(39, 74, 60);

}
.msg-box-date-read{
     transition: 0.1s all ease-in ;
     /* opacity: 0; */
}
.msg-box-date-unread{
     transition: 0.1s all ease-in ;
     /* opacity: 0; */
}

.msg-box-date-seen{
     transition: 0.1s all ease-in ;
     color:rgb(54, 185, 229);
     fill: rgb(54, 185, 229);
     /* opacity: 0; */
}

.messages-body-box-cont{
     scroll-behavior: smooth;
     transition: 100ms all ease-in-out;
     scrollbar-shadow-color: aquamarine;
     scrollbar-width: thin;
}