body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.activeLinavAcc{

       color :white;
       background-color :#4554;

}
.subnav:active {

  color :white;
       background-color :#4554;
}


/* posts box  */
* {
  box-sizing: border-box;
}




/* share options  */


.share-btn{
    position: relative;
    border: none;
    background: #fff;
    color: #ff7d7d;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 30px;
    padding-top: 2.5px;
    padding-right: 3px;
    cursor: pointer;
    z-index: 2;
}

.share-options{
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: auto;
    height: auto;
    transform-origin: bottom left;
    transform: scale(0);
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background: rgba(15, 15, 15, 0.5);
    color: #fff;
    padding: 20px;
    font-family: 'roboto';
    transition: .5s;
    transition-delay: .5s;;
}

.share-options.active{
    transform: scale(1);
    transition-delay: 0s;
}

.title{
    opacity: 0;
    transition: .5s;
    transition-delay: 0s;
    font-size: 20px;
    text-transform: capitalize;
    border-bottom: 1px solid #fff;
    width: fit-content;
    padding: 0 20px 3px 0;
}

.social-media{
    opacity: 0;
    transition: .5s;
    transition-delay: 0s;
    width: 250px;
    height: 120px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px 0;
}

.social-media-btn{
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #000;
    color: #fff;
    line-height: 50px;
    font-size: 25px;
    cursor: pointer;
    margin: 0 5px;
    text-align: center;
}

.social-media-btn:nth-child(1){
    background: #FFA654;
}

.social-media-btn:nth-child(2){
    background: #25D366;
}

.social-media-btn:nth-child(3){
    background: #E4405F;
}

.social-media-btn:nth-child(4){
    background: #1DA1F2;
}

.social-media-btn:nth-child(5){
    background: #1877F2;
}

.social-media-btn:nth-child(6){
    background: #0A66C2;
}

.link-container{
    opacity: 0;
    transition: .5s;
    transition-delay: 0s;
    width: 100%;
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 40px;
    background-color: #fff;
    overflow: hidden;
    padding: 0 10px;
}

.link{
    width: 80%;
    height: 100%;
    line-height: 40px;
    color: #000;
}

.copy-btn{
    position: absolute;
    right: 0;
    cursor: pointer;
    background: #000;
    color: #fff;
    border: none;
    height: 100%;
    width: 30%;
    text-transform: capitalize;
    font-size: 16px;
}

.share-options.active .title,
.share-options.active .social-media,
.share-options.active .link-container{
    opacity: 1;
    transition: .5s;
    transition-delay: .5s;
}


#clickME{
animation: blinkanimation infinite 1000ms linear ;
-o-animation: blinkanimation infinite 1000ms linear ;
-webkit-animation: blinkanimation infinite 1000ms linear ;
-moz-animation: blinkanimation infinite 1000ms linear ;
width: 100%;
margin: 0 auto;}
@keyframes blinkanimation {
  10%{opacity: 0;}
  25%{opacity: 0.3;}
  50%{opacity: 0.5;}
  75%{opacity: 0.7;}
  90%{opacity: 1;}
  100%{opacity: 1;}
}
@-o-keyframes blinkanimation {
  10%{opacity: 0;}
  25%{opacity: 0.3;}
  50%{opacity: 0.5;}
  75%{opacity: 0.7;}
  90%{opacity: 1;}
  100%{opacity: 1;}
}
@-webkit-keyframes blinkanimation {
  10%{opacity: 0;}
  25%{opacity: 0.3;}
  50%{opacity: 0.5;}
  75%{opacity: 0.7;}
  90%{opacity: 1;}
  100%{opacity: 1;}
}

.sellerBottomNav{

display: inline-block !important;
/* text-align: center; */
float: right;
overflow: hidden;
text-overflow: ellipsis;
height: 8vh;
/* width: 20vh; */


}

.flutterPayButton:hover{
border: 1px solid rgb(198, 230, 198);
background-color: rgba(247, 177, 0, 0.933);

}
.flutterPayButton{

background-color: rgb(255, 204, 0);
padding: 10px;
width: 300px;
margin: 0 auto;
cursor: pointer;
border-radius: 5px;
border-bottom: 0.2px solid rgb(41, 90, 41);
border-top: 0.2px solid rgb(41, 90, 41);
font-size: larger;
font-weight: bold;
color: #23294b;
box-shadow: 3px  0.5px 1px 0.9px rgb(158, 172, 131);
user-select: none;
}

.order-seller-items:hover{
overflow: auto;
scrollbar-width: 5px;
-ms-scrollbar-3dlight-color: #01531e;
}
.order-seller-products:hover{
overflow-x: auto !important;
overflow-y: auto !important;
}
 #btnContainer{
    padding: 50px;
 
}
@media only screen and (max-width:300px) {
    #btnContainer{
    padding: 1px;
 
}
}

#ref-copyBtn:hover{
background-color: rgb(154, 188, 218)!important;
transition: all 1s ease-in;
color: aliceblue;
cursor: pointer;
}

