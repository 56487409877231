.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.order-open{
  height: 100% !important;
  overflow: hidden;
  transition: all 300ms;
}

.orderCont{
font-size: medium;
}

.order-val{
display: inline-block;
margin-left:20px;
float: right;
margin:0;
padding:0;
/* border-bottom: 0.1px solid grey; */
background-color:'grey';

}

.order-key{
display: inline-block;
color:darkgrey;
margin:0px;
background-color:'grey';
padding:0
}
@media only screen and (max-width:600px) {

.order-product-detail-tab{

  background-color: #3b4e54 !important;
  width: 100px !important;
  color:white;
  font-size: 14px !important;
  height: 100px !important;
  
}
  .tab-cont{
  display: flex;
  animation: moveTabs infinite 10s  ;
  
  }
  @keyframes moveTabs {
    0%{
    margin-left: -0px;
    
    }
    25%{
    margin-left: -100px;
    
    }
    50%{
    margin-left: -300px;
    
    }
    75%{
    margin-left: -400px;
    
    }
    100%{
    margin-left: -500px;
    }
  }


}
.users-p-search input{
  background-color: inherit !important;
  width: inherit;
  height: inherit;
  

}
.chat-box{
  transition: all 0.3s ease-out;
  cursor: pointer;
}
.chat-box:hover{
  background-color: rgb(38, 48, 49);
}


.users-p-search .ant-select-selector {
  background-color: #eafff0 !important;
  width: inherit;
  height: inherit;
     border-radius: 10px !important;

  color:rgb(56, 58, 56) !important

}
.users-p-search {
  width: 100% !important;
  background-color: #9ca79f !important;
  height: inherit;
     border-radius: 10px !important;
  border-radius: 10px;

  color:rgb(56, 58, 56) !important

}
.attention_needed:hover{
  background-color: z#1a6c85 !important;
  animation: none;
}
.attention_needed{
  animation: attention infinite 1s  ;
  cursor: pointer;
  
}
  @keyframes attention {
    0%{
    opacity: 0;
    
    }
    
    100%{
    opacity: 1;
    }
  }
